
/* 

http://hourly-app.com/

"Neue Haas Grotesk Text Bd", Helvetica, "Helvetica Neue", Arial, sans-serif

--rich-black-fogra-39: #0a0a0aff;

--eerie-black: #1a181aff;

--davys-grey: #505050ff;

--persian-red: #d1342fff;

--black-olive: #434038ff;

--dutch-white: #eee3c1ff; */

html {
  background: #1a181aff;
}

.App {
  text-align: center;
  background-color: #0a0a0aff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0a0a0aff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #eee3c1ff;
}

.App-link {
  color: #d1342fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
